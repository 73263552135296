<template>
  <div class="personal-info">
    <el-row class="content-box">
      <el-col :span="24"><div class="title">个人信息</div></el-col>
      <el-col :span="24"><div class="input-title">*<span>身份证正面</span></div> </el-col>
      <div class="identity-card">
        <img @click="uploadIdCardImage()" v-loading.fullscreen.lock="fullscreenLoading" v-if="!frontSrc" src="../../assets/finance/identity-card-front.png">
        <img @click="uploadIdCardImage()" v-loading.fullscreen.lock="fullscreenLoading" v-if="frontSrc" :src="frontSrc">
      </div>
      <el-col :span="24"><div class="input-title">*<span>身份证背面</span></div> </el-col>
      <div class="identity-card">
        <img @click="uploadBankBack()" v-if="!backSrc" src="../../assets/finance/identity-card-back.png">
        <img @click="uploadBankBack()"  v-if="backSrc" :src="backSrc">
      </div>
      <el-col :span="24">
        <div class="identity-card-prompt">
          <span>1、证件照片须为彩色扫描件或彩色图片</span>
          <span>2、图片仅支持BMP、JPG、JPEG、PNG格式，大小不超过2MB</span>
        </div>
      </el-col>
      <el-col :span="24">
        <div class="identity-card-input-prompt">
          <span>以下信息须与证件照片信息一致</span>
        </div>
      </el-col>
      <el-row class="identity-card-input-box border-bottom">
        <el-col :span="6">
          <div class="input-title">*<span>证件姓名</span></div>
        </el-col>
        <el-col :span="18" class="item-center">
          <input v-model="formData.name" disabled class="identity-card-input" placeholder="上传身份证图片自动识别"/>
        </el-col>
      </el-row>
      <el-row class="identity-card-input-box border-bottom">
        <el-col :span="6">
          <div class="input-title">*<span>证件号码</span></div>
        </el-col>
        <el-col :span="18" class="item-center">
          <input v-model="formData.idCardNo" disabled class="identity-card-input" placeholder="上传身份证图片自动识别"/>
        </el-col>
      </el-row>

      <el-row class="identity-card-input-box border-bottom">
        <el-col :span="24">
          <div class="input-title">*<span>身份证有效期</span></div>
        </el-col>
        <el-col :span="11" class="item-center">
          <input v-model="formData.idCardStartDate" disabled class="identity-card-input" placeholder="开始时间"/>
        </el-col>
        <el-col :span="2">
          至
        </el-col>
        <el-col :span="11" class="item-center">
          <input v-model="formData.idCardEndDate" disabled class="identity-card-input" placeholder="结束时间"/>
        </el-col>
      </el-row>

    </el-row>


    <el-row class="content-box" style="padding-bottom: 20px;">
      <el-col :span="24"><div class="title">银行信息</div></el-col>

      <el-row class="identity-card-input-box border-bottom">
        <el-col :span="6">
          <div class="input-title">*<span>银行卡号</span></div>
        </el-col>
        <el-col :span="15" class="item-center">
          <input v-model="this.formData.bankNumber" v-if="this.bankNumberDisable" class="identity-card-input" disabled placeholder="请输入借记卡银行卡号"/>
          <input v-model="this.formData.bankNumber" v-if="!this.bankNumberDisable" class="identity-card-input" placeholder="请输入借记卡银行卡号"/>
          <i class="el-icon-camera"></i>
        </el-col>
        <el-col :span="3" class="item-center">
          <img @click="uploadBankCardImage()" src="../../assets/upload-bank.svg">
        </el-col>

        <el-col :span="24">
          <div class="identity-card-input-prompt">
            <span>点击右侧相机图标可上传照片识别银行卡</span>
          </div>
        </el-col>
      </el-row>

      <el-col :span="6" class="identity-card-address margin-10">
        <div class="input-title">*<span>开户银行</span></div>
      </el-col>
      <el-col :span="18" class="item-center">
        <input v-model="this.formData.bankName" class="identity-card-input" disabled placeholder="请输入开户银行"/>
      </el-col>

      <el-row class="identity-card-input-box border-bottom">
        <el-col :span="6">
          <div class="input-title">*<span>开户支行</span></div>
        </el-col>
        <el-col :span="18" class="item-center">
          <input class="identity-card-input" v-model="this.formData.bankBranch" placeholder="请输入开户支行信息"/>
        </el-col>
      </el-row>
    </el-row>

    <el-row class="content-box" style="padding-bottom: 20px;">
      <el-col :span="6">
        <div class="input-title">*<span>手机号</span></div>
      </el-col>
      <el-col :span="18" class="item-center">
        <input v-model="this.merchantPhone" class="identity-card-input" disabled placeholder="请输入借记卡银行卡号"/>
        <i class="el-icon-camera"></i>
      </el-col>

      <el-col v-if="isEdit === 0" :span="6">
        <div class="input-title">*<span>验证码</span></div>
      </el-col>
      <el-col v-if="isEdit === 0" :span="10" class="item-center">
        <input v-model="this.formData.verificationCode" class="identity-card-input" placeholder="请输入手机验证码"/>
        <i class="el-icon-camera"></i>
      </el-col>
      <el-col v-if="isEdit === 0" :span="8" class="item-center">
        <button id="sendCode" class="sendCodeBtn" @click="sendVerificationCode">
          {{ clockShow ? clock + 's后重新获取验证码' : '获取验证码' }}
        </button>
      </el-col>
    </el-row>

    <button type="button" v-if="isEdit === 0" @click="submit" class="sub-btn">提交</button>

    <div class="">
      <input ref="imgInput" class="img-upload-btn hidden" type="file" id="prompt-img-btn" accept="image/*">
      <input ref="imgBackInput" class="img-upload-btn hidden" type="file" id="prompt-img-btn" accept="image/*">
      <input ref="bankImgInput" class="img-upload-btn hidden" type="file" id="prompt-img-btn" accept="image/*">
    </div>
  </div>
</template>

<style>
.personal-info .sendCodeBtn {
  width: 100%;
  color: white;
  min-height: 30px;
  border: none;
  background-color: #409eff;
  padding: 5px;
}

.personal-info .sub-btn {
  letter-spacing: 5px;
  background-color: #409eff;
  color: white;
  padding: 10px;
  border-radius: 5px;
  border: 0;
  width: 80%;
  font-size: 18px;
  font-weight: bold;
  margin-top: 10px;
}

.personal-info .identity-card-input-box {
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 16px;
}

.personal-info .border-bottom {
  border-bottom: 1px solid #F4F4F4;
}

.personal-info .item-center {
  display: flex!important;
  align-items: center!important;
}

.personal-info .identity-card-input {
  border: none;
  outline: none;
  width: 100%;
}

.personal-info .identity-card-input::placeholder {
  color: #A1A1A1;
}

.personal-info .identity-card-input-prompt {
  background-color: #EFF5FB;
  color: #A1A1A1;
  text-align: left;
  padding: 5px 10px 5px 10px;
  margin-top: 15px;
}

.personal-info .identity-card-prompt {
  display: grid;
  font-size: 12px;
  color: #A1A1A1;
  text-align: left;
}

.personal-info .identity-card {
  padding: 10px 0 10px 0;
  width: 100%;
}

.personal-info .identity-card img {
  width: 200px;
  height: 130px;
}

.personal-info .title {
  text-align: left;
  font-size: 20px;
  font-weight: bold;
}

.personal-info .input-title {
  color: red;
  padding: 5px 0 5px 0;
  text-align: left;
}

.personal-info .input-title span {
  color: black;
  padding-left: 5px;
}

.personal-info {
  width: 100vw; /* 使div的宽度为视口宽度 */
  min-height: 100vh; /* 使div的高度为视口高度 */
  padding-bottom: 50px;
  background-color: #f0f0f0;
  font-size: 16px;
}

.personal-info .page-title {
  padding-bottom: 20px;
  padding-top: 10px;
  font-size: 20px;
  font-weight: 400;
}

.personal-info .content-box {
  background-color: white;
  padding: 10px 20px 10px 20px;
  margin-bottom: 10px;
}

.sub-body {
  color: #007BFF;
  border: 2px solid #007BFF;
  padding: 5px 10px;
  border-radius: 5px;
  min-width: 100px;
}
</style>

<script>
import axios from "axios";

export default {
  data() {
    return {
      isEdit: 1,
      clock: 60,
      showInviter: false,
      clockShow: false,
      isVisible: false,
      frontSrc: null,
      imgType: null,
      backSrc: null,
      bankNumberDisable: true,
      formData: {
        userId: null,
        name: null,
        idCardNo: null,
        faceImg: null,
        backImg: null,
        idCardStartDate: null,
        idCardEndDate: null,
        bankName: null,
        bankNumber: null,
        bankCode: null,
        bankBranch: null,
        verificationCode: null,
      },
      fullscreenLoading: false,
      merchantPhone: null,
      userInfo: null
    }
  },
  created () {
    this.userInfo = JSON.parse(sessionStorage.getItem('user'));
    if (this.userInfo === null) {
      this.$router.push({name : 'expertRegistration'})
    }
    this.merchantPhone = this.userInfo.phone;

    this.formData.userId = this.userInfo.id;
    this.getUserIdCardInfo();
  },
  mounted() {
    this.$refs.imgBackInput.addEventListener('change', this.uploadBackImage)
    this.$refs.imgInput.addEventListener('change', this.uploadImage);
    this.$refs.bankImgInput.addEventListener('change', this.uploadBankImg);
  },
  beforeUnmount() {
    this.$refs.imgBackInput.removeEventListener('change', this.uploadBackImage)
    this.$refs.imgInput.removeEventListener('change', this.uploadImage);
    this.$refs.bankImgInput.removeEventListener('change', this.uploadBankImg);
  },
  methods: {
    submit() {
      if (this.isEdit === 1) {
        return;
      }

      if (this.formData.idCardNo === null || this.formData.faceImg === null || this.formData.backImg === null || this.formData.idCardStartDate === null) {
        alert('请上传身份证识别身份信息');
        return;
      }

      if (this.formData.bankName === null || this.formData.bankCode === null) {
        alert('请上传银行卡识别银行信息');
        return;
      }

      if (this.formData.bankBranch === null || this.formData.bankBranch === '') {
        alert('请输入开户支行信息');
        return;
      }

      if (this.formData.verificationCode === null || this.formData.verificationCode === '') {
        alert('请输入验证码');
        return;
      }

      axios.post(this.$store.state.host + 'tt/wechat/account/save/bank/info', this.formData).then(response => {
        if (response.data.code !== '200') {
          alert(response.data.msg);
        } else {
          alert('保存成功！')
          this.$router.push({name : 'personalCenter'});
        }
      })
    },
    setTime() {
      if (this.clock === 0) {
        this.clock = 60
        this.clockShow = false
        return
      } else {
        this.clock--
      }

      setTimeout(() => { this.setTime() }, 1000)
    },
    sendVerificationCode() {
      //发送短信验证码
      if (!this.clockShow) {
        axios.get(this.$store.state.host + 'tt/wechat/user/verify/code/uid', {
          params: {
            uid: this.userInfo.id
          }
        }).then(() => {
          //触发倒计时
          this.setTime()
          this.clockShow = true
        }).catch(function (msg) {
          alert('发送短信验证失败')
        })
      }
    },
    async uploadBankImg() {
      const file = event.target.files[0];
      if (!file) {
        console.log('No file selected');
        return;
      }

      var fileSize = file.size; // 获取文件大小（字节）
      var maxSize = 5 * 1024 * 1024; // 设置最大文件大小（这里设置为1MB）
      if (fileSize > maxSize) {
        alert('图片大小不能超过5MB！');
        return false;
      }

      this.fullscreenLoading = true;
      await axios.post(this.$store.state.host + 'tt/wechat/common/aliyun/img/ocr', {
        file: file,
        imageType: "BankCard",
        uid: this.userInfo.id
      }, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(response => {
        if (response.data.body.imageContent === null) {
          this.fullscreenLoading = false;
          alert('图片识别失败！')
          return;
        }

        const bankCardObject = JSON.parse(response.data.body.imageContent);
        this.bankNumberDisable = false;
        this.formData.bankName = bankCardObject.bankName;
        this.formData.bankNumber = bankCardObject.cardNumber;
        this.formData.bankCode = bankCardObject.bankCode;
        this.fullscreenLoading = false;
      }).catch(function () {
        alert('上传失败图片失败！')
        this.fullscreenLoading = false;
      });
    },
    getUserIdCardInfo() {
      axios.get(this.$store.state.host + 'tt/wechat/account/bank/info', {
        params: {
          userId: this.userInfo.id
        }
      }).then(response => {
        let bankInfo = response.data.body
        if (bankInfo.isEdit === 0 && bankInfo.bankNumber.length > 0) {
          this.bankNumberDisable = false;
        }

        this.formData.name = bankInfo.name;
        this.formData.idCardNo = bankInfo.idCardNo;
        this.formData.idCardStartDate = bankInfo.idCardStartDate;
        this.formData.idCardEndDate = bankInfo.idCardEndDate;
        this.formData.faceImg = bankInfo.faceImg;
        this.formData.backImg = bankInfo.backImg;
        this.frontSrc = bankInfo.faceImgUrl;
        this.backSrc = bankInfo.backImgUrl;
        this.formData.bankNumber = bankInfo.bankNumber;
        this.formData.bankName = bankInfo.bankName;
        this.formData.bankCode = bankInfo.bankCode;
        this.formData.bankBranch = bankInfo.bankBranch;
        this.isEdit = bankInfo.isEdit;
      })
    },
    uploadIdCardImage() {
      if (this.isEdit === 1) {
        return;
      }
      this.$refs.imgInput.click();
    },
    uploadBankCardImage() {
      if (this.isEdit === 1) {
        return;
      }
      this.$refs.bankImgInput.click();
    },
    uploadBankBack() {
      if (this.isEdit === 1) {
        return;
      }
      this.$refs.imgBackInput.click();
    },
    async uploadBackImage(event) {
      if (this.isEdit === 1) {
        return;
      }
      const file = event.target.files[0];
      if (!file) {
        console.log('No file selected');
        return;
      }

      var fileSize = file.size; // 获取文件大小（字节）
      var maxSize = 5 * 1024 * 1024; // 设置最大文件大小（这里设置为1MB）
      if (fileSize > maxSize) {
        alert('图片大小不能超过5MB！');
        return false;
      }

      this.fullscreenLoading = true;
      await axios.post(this.$store.state.host + 'tt/wechat/common/aliyun/img/ocr', {
        file: file,
        imageType: "IdCard",
        uid: this.userInfo.id
      }, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(response => {
        if (response.data.body.imageContent === null) {
          this.fullscreenLoading = false;
          alert('图片识别失败！')
          return;
        }

        this.backSrc = response.data.body.url;

        const idCardObject = JSON.parse(response.data.body.imageContent);
        const date = idCardObject['validPeriod'].split('-');

        this.formData.idCardStartDate = date[0];
        this.formData.idCardEndDate = date[1];
        this.formData.backImg = response.data.body.path;
        this.fullscreenLoading = false;
      }).catch(function (error) {
        alert('上传失败图片失败！')
        this.fullscreenLoading = false;
      });
    },
    async uploadImage(event) {
      if (this.isEdit === 1) {
        return;
      }

      const file = event.target.files[0];
      if (!file) {
        console.log('No file selected');
        return;
      }

      var fileSize = file.size; // 获取文件大小（字节）
      var maxSize = 5 * 1024 * 1024; // 设置最大文件大小（这里设置为1MB）
      if (fileSize > maxSize) {
        alert('图片大小不能超过5MB！');
        return false;
      }

      this.fullscreenLoading = true;
      await axios.post(this.$store.state.host + 'tt/wechat/common/aliyun/img/ocr', {
        file: file,
        imageType: "IdCard",
        uid: this.userInfo.id
      }, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(response => {
          this.frontSrc = response.data.body.url;
          this.formData.faceImg = response.data.body.path;
          if (response.data.body.imageContent === null) {
            this.fullscreenLoading = false;
            alert('图片识别失败！')
            return;
          }

          const idCardObject = JSON.parse(response.data.body.imageContent);
          this.formData.name = idCardObject.name;
          this.formData.idCardNo = idCardObject.idNumber;
          this.fullscreenLoading = false;
      }).catch(function () {
        alert('上传图片失败！');
        this.fullscreenLoading = false;
      });
    }
  }
}
</script>