<template>
  <div class="merchant-list">
    <div v-for="record in items" :key="record.id" class="invited-box">
      <div class="box">
        <div class="img">
          <img :src="record.avatar">
        </div>
        <div class="text">
          <span v-text="record.merchantName" class="name"></span><br>
          <span class="msg">注册时间: &nbsp;&nbsp;{{ record.registerDate }}</span>
        </div>
      </div>
    </div>
    <div v-if="!hasData" style="margin-top: 10px;">暂无数据</div>
    <div ref="observerElement"></div>
  </div>
</template>

<style>
body {
  margin: 0;
}

.merchant-list {
  min-height: 100%;
  background-color: white;
  padding: 10px 25px 10px 25px;
}

.merchant-list .invited-box .box {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  border-bottom: 1px #888888 solid;
  padding-top: 10px;
  padding-bottom: 10px;
}

.merchant-list .invited-box .text {
  padding-top: 5px;
  padding-left: 20px;
  text-align: left;
  width: 80%;
}
.merchant-list .invited-box .text .name {
  font-size: 16px;
  font-weight: bold;
}
.merchant-list .invited-box .text .msg {
  font-size: 12px;
  color: #aaaaaa;
}
.merchant-list .invited-box img {
  width: 50px;
}
</style>

<script>
import axios from "axios";
import {onBeforeUnmount, onMounted, ref} from "vue";
import {useStore} from "vuex";

export default {
  name: "invitedList",
  setup() {
    let hasData = ref(false);
    const items = ref([]); // 列表数据
    const observerElement = ref(null); // 用于触发加载的元素

    const userInfo = JSON.parse(sessionStorage.getItem('user'));
    if (userInfo === null) {
      return {
        items,
        hasData,
        observerElement
      };
    }

    const observer = ref(null); // IntersectionObserver 实例
    let page = 1; // 初始化页码
    let pageSize = 20;
    let page_total = 1;
    const store = useStore();

    // 加载更多内容的函数
    const loadMoreItems = () => {
      // 模拟异步加载数据
      if (page > page_total) {
        return;
      }

      axios.get(store.state.host + 'tt/wechat/user/merchant/page', {
        params: {
          pageIndex: page,
          pageSize: pageSize,
          condition: userInfo.id
        }
      }).then((records) => {
        if (records.data.body.data.length > 0) {
          items.value = [...items.value, ...records.data.body.data];
          hasData.value = true;
          page++; // 页码递增
        }
        page_total = records.data.body.page.page_total;
      })
    };

    // 创建 IntersectionObserver 实例
    onMounted(() => {
      observer.value = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          loadMoreItems();
        }
      }, {
        root: null, // 视口作为根元素
        rootMargin: '0px',
        threshold: 1.0 // 完全可见时触发
      });

      if (observerElement.value) {
        observer.value.observe(observerElement.value);
      }
    });

    // 停止观察并断开 IntersectionObserver 实例
    onBeforeUnmount(() => {
      if (observer.value) {
        observer.value.disconnect();
      }
    });

    return {
      items,
      hasData,
      observerElement
    };
  },
  data() {
    return {
      userInfo: [],
      records: null
    }
  },
  created() {
    this.userInfo = JSON.parse(sessionStorage.getItem('user'));
    if (this.userInfo === null) {
      this.$router.push({name : 'expertRegistration'})
    }
  },
  mounted() {
    document.title = '邀请商家列表'; // 设置页面标题
  }
}
</script>
