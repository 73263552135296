<template>
  <div class="personalCenter" style="background-color: #f0f0f0">
    <div class="personalUserInfo">
      <div class="avatar">
        <img :src="this.userInfo.avatar">
      </div>
      <div class="userMessage">
        <div>
          <span class="nickname">{{ userInfo.nickName }}</span>
          <span style="margin-left: 10px">{{ userInfo.phone }}</span>
        </div>
        <div class="tip-box">
          <div v-if="userInfo.agentLevel === 1" class="tip">
            <img src="../assets/personal/level2.png">
            达人
          </div>
          <div v-if="userInfo.agentLevel === 2" class="tip">
            <img src="../assets/personal/level2.png">
            大达人
          </div>
          <div class="tip">
            <img src="../assets/personal/authourize.png">
            已认证
          </div>
        </div>
      </div>
    </div>

    <div class="center">
      <div class="income">
        <div class="totalRevenue">
          <div class="title-box">
            <span class="title">总收益金额（元）</span>
            <br>
            <span class="money" v-text="userInfo.accountUserInfoResponse.totalIncome"></span>
          </div>
          <a class=withdrawals-link href="/apply/withdrawal">
            <button class="btn">申请提现</button>
          </a>
        </div>
        <div class="content-box">
          <div class="content-describe">
            <span class="describe">已提现</span>
            <span>{{userInfo.accountUserInfoResponse.withdrawnAmount}}</span>
          </div>
          <div class="segmentation"></div>
          <div class="content-describe">
            <span class="describe">提现中</span>
            <span>{{userInfo.accountUserInfoResponse.withdrawingAmount}}</span>
          </div>
          <div class="segmentation"></div>
          <div class="content-describe">
            <span class="describe">待入账</span>
            <span>{{userInfo.accountUserInfoResponse.tempAmount}}</span>
          </div>
          <div class="segmentation"></div>
          <div class="content-describe">
            <span class="describe">可提现</span>
            <span>{{userInfo.accountUserInfoResponse.availableForWithdrawalAmount}}</span>
          </div>
        </div>
      </div>
    </div>

    <div class="center">
      <div class="qrCodeNav">
        <div class="sage box" id="sage" @click="showSage">
          <img src="../assets/personal/personal-qr-code.png">
          <span>达人邀请码</span>
        </div>
        <div class="business box" id="business" @click="showBusiness">
          <img src="../assets/personal/personal-qr-code.png">
          <span>商家邀请码</span>
        </div>
      </div>
    </div>

    <div class="center">
      <div class="my-order">
        <div class="title">
          我的订单
        </div>
        <div class="function-group">
          <a href="/merchant/records" class="function-box">
            <img href="/merchant/records" src="../assets/personal/shop-collect.png">
            <span>商户记录</span>
          </a>
          <a href="/order/records" class="function-box">
            <img src="../assets/personal/e-bike.png">
            <span>订单记录</span>
          </a>
          <a href="/invited/list" class="function-box">
            <img src="../assets/personal/fa-address-card.png">
            <span>达人记录</span>
          </a>
          <a href="/detailed/list" class="function-box">
            <img src="../assets/personal/money-collect.png">
            <span>佣金记录</span>
          </a>
        </div>
      </div>
    </div>

    <div class="center">
      <div class="nav">
        <a class="nav-box" @click="showQr" id="showQr">
          <span><img src="../assets/personal/iconPark-currency.svg"><span style="margin-left: 10px">专属提现财务客服</span></span>
          <img src="../assets/nav.png">
        </a>
        <a class="nav-box" href="/learn/center">
          <span><img src="../assets/personal/iconPark-currency.svg"><span style="margin-left: 10px">学习中心</span></span>
          <img src="../assets/nav.png">
        </a>
        <a class="nav-box" href="/personal/information">
          <span><img src="../assets/personal/iconPark-currency.svg"><span style="margin-left: 10px">编辑银行账户信息</span></span>
          <img src="../assets/nav.png">
        </a>
      </div>
    </div>

    <div class="center">
        <div class="model-box" id="qr-model-box">
          <div class="model-content">
            <div class="title">
              <span class="modal-title" style="font-size: 20px;font-weight: bold">专属提现客服</span>
            </div>
            <img :src=imgSrc>
            <img v-if="imgSrc === null" src="../assets/loading.gif">
            <div style="display: grid;color: #999999;padding: 5px 0 20px 0;">
              <span>长按识别加我为好友</span>
              <span>如未识别请截图至微信扫一扫识别</span>
            </div>
            <button class="close-btn" id="qr-close">关闭</button>
          </div>
        </div>

      <div v-if="showOfficialModel" class="model-box" id="official-qr-model-box" style="display: flex;">
        <div class="model-content">
          <div class="title">
            <span class="modal-title" style="font-size: 20px;font-weight: bold">请先关注外卖速通公众号</span>
            <span class="modal-title" style="font-size: 20px;font-weight: bold">注册成为推广达人</span>
          </div>
          <img src="../assets/tt-qr.jpg">
          <div style="display: grid;color: #999999;padding: 5px 0 20px 0;">
            <span>长按识别微信公众号</span>
            <span>如未识别请截图至微信扫一扫识别</span>
          </div>
          <button class="close-btn" @click="hideOfficialModel" id="official-qr-close">关闭</button>
        </div>
      </div>
    </div>
</div>
</template>


<style>
.personalCenter .personalUserInfo .tip-box {
  width: 80%;
  display: flex;
  margin-top: 10px;
}
.personalCenter .personalUserInfo .tip {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  min-width: 55px;
  background-color: white;
  margin-right: 10px;
  border-radius: 5px;
  border: 2px solid #dedede;
  font-size: 10px;
  color: #7d8087;
}


.personalCenter .my-order {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 90%;
  padding: 10px;
  background-color: white;
  margin-top: 35px;
  border-radius: 7px;
}

.personalCenter .my-order .title {
  text-align: left;
  font-size: 18px;
  width: 90%;
  border-bottom: 1px solid #aaaaaa;
  padding-top: 10px;
  padding-bottom: 10px;
}
.personalCenter .my-order .function-group {
  width: 100%;
  padding-top: 15px;
  padding-bottom: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}
.personalCenter .my-order .function-box {
  width: 25%;
  display: grid;
  justify-items: center;
  justify-content: center;
  text-decoration: none;
  color: black;
}
.personalCenter .my-order .function-box img {
  width: 30px;
  height: 30px;
}
.personalCenter .my-order .function-box span {
  font-size: 12px;
  font-weight: 500;
  margin-top: 5px;
}
.personalCenter {
  /*height: 100vh; !* 使div的高度为视口高度 *!*/
  width: 100vw; /* 使div的宽度为视口宽度 */
  padding-bottom: 50px;
  background-color: #f0f0f0;
}
.personalCenter .nav {
  margin-top: 35px;
  width: 90%;
  padding: 10px 30px 10px 30px;
  border-radius: 7px;

  background-color: white;
}
.personalCenter .nav .nav-box {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  padding-top: 10px;
  font-size: 16px;
  font-weight: bold;
  text-decoration: none;
  color: black;
  width: 100%;

  margin-top: 0;
  padding-bottom: 20px;
  border-bottom: 1px #aaaaaa solid;
}
.personalCenter .nav .nav-box span {
  align-items: center;
  display: flex;
}
.personalCenter .nav .nav-box img {
  width: 20px;
}

.personalCenter .nav .nav-box:first-child {
}

.personalCenter .nav .nav-box:last-child {
  margin-top: 10px;
  padding-bottom: 10px;
  border-bottom: 0;
}

.personalCenter .income .content-describe {
  text-align: left;
  display: grid;
  justify-content: center;
}

.personalCenter .income .content-box {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  height: 20px;
  width: 100%;
}

.personalCenter .income .totalRevenue {
  width: 100%;
  height: 50%;
  padding: 10px;
  font-size: 16px;
  font-weight: bold;
  background-color: white;
  border-radius: 10px 10px 0 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.personalCenter .income .totalRevenue .title-box {
  line-height: 30px;
  text-align: left;
  color: black;
}
.personalCenter .income .totalRevenue .money {
  font-size: 30px;
  color: red;
}
.personalCenter .income .totalRevenue img {
  position: absolute;
  margin-top: 3px;
  width: 20px;
  margin-left: 5px;
}

.personalCenter .income .totalRevenue .withdrawals-link .btn {
  border: none;
  border-radius: 30px;
  padding: 10px;
  color: white;
  background-color: red;
  font-size: 16px;
  font-weight: bold;
  width: 120px;
  align-items: center;
  margin-top: 2px;
}

.personalCenter .income {
  margin-top: -75px;
  width: 90%;

  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  font-size: 16px;
  font-weight: bold;

  border-radius: 5px;
  padding: 10px;
  height: 140px;
  background-color: white;
}
.personalCenter .income .segmentation {
  width: 1px;
  height: 30px;
  margin-top: 10px;
  background-color: #aaaaaa;
}

.personalCenter .income .describe {
  font-size: 12px;
}

.personalCenter .qrCodeNav {
  padding: 25px 10px 25px 10px;
  margin-top: 35px;
  width: 90%;
  background-color: #5390ff;
  border-radius: 7px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.personalCenter .qrCodeNav .box {
  width: 45%;
  align-items: center;
  background-color: white;
  border-radius: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: 10px;
  font-size: 26px;
}

.personalCenter .qrCodeNav img {
  width: 26px;
  height: 26px;
}
.personalCenter .qrCodeNav span {
  font-size: 18px;
  font-weight: bold;
}
.personalCenter .qrCodeNav a {
  text-decoration: none;
  color: black;
}
.personalCenter .personalUserInfo {
  background-image: url("../assets/personal-user-background.png");
  background-position: center;
  background-size: cover;
  padding-top: 20px;
  font-size: 16px;
  font-weight: bold;
  height: 200px;
  background-color: #497ef2;
  display: flex;
  justify-content: flex-start;
}
.personalCenter .personalUserInfo .avatar {
  margin-top: 20px;
  margin-left: 10%;
}
.personalCenter .personalUserInfo .userMessage {
  width: 80%;
  margin-left: 20px;
  margin-top: 20px;
  text-align: left;
}
.personalCenter .personalUserInfo .userMessage .nickname {
  font-size: 22px;
}
.personalCenter .personalUserInfo .userMessage .phoneID {
  margin-top: 10px;
}
.personalCenter .personalUserInfo .userMessage .phoneID img {
  width: 20px;
  margin-left: 5px;
  position: absolute;
}
.personalCenter .personalUserInfo .avatar img {
  width: 55px;
  height: 55px;
  border: 4px solid white;
  border-radius: 50%;
}

/* 模态框 start */
.personalCenter .model-box {
  flex-wrap: wrap;
  justify-content: center;
  /* 隐藏模态框 */
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.45);
}
.personalCenter .model-box .model-content {
  width: 90%;
  height: 450px;
  margin-top: 100px;
  border-radius: 5px;
  padding: 0 20px;
  /* 盒子阴影 */
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.2);
  background-color: #fff;
  padding-bottom: 10px;
}
.personalCenter .model-box .model-content .title {
  /* 弹性布局 */
  display: grid;
  /* 让子元素水平与两端对齐 */
  justify-content: center;
  min-height: 60px;
  padding-top: 20px;
  /* 鼠标移入呈现移动光标 */
  cursor: move;
}
.personalCenter .model-box .close {
  font-size: 40px;
}
.personalCenter .model-box .close-btn {
  border: 0;
  padding: 10px;
  width: 80%;
  margin-top: 10px;
  border-radius: 20px;
  color: white;
  font-size: 18px;
  background-color: #598bfd;
  margin-bottom: 20px;
}

.personalCenter .model-box .model-content .title span {
  font-size: 18px;
  font-weight: bold;
  color: #333;
}
.personalCenter .model-box .model-content form .form-input input:hover {
  border-color: #c0c4cc;
}
/* 输入框获取焦点变色 */
.personalCenter .model-box .model-content form .form-input input:focus {
  border-color: #409eff;
}
.personalCenter .model-box .model-content form .form-input button {
  /* 让按钮浮动到右侧 */
  float: right;
  margin-top: 10px;
}

.personalCenter .model-box img {
  width: 80%;
}

/* 模态框 end */
</style>

<script>
import $ from 'jquery';
import axios from "axios";

export default {
  data() {
    return {
      userInfo: {
        id: 0,
        commissionUserId: 0,
        commissionUserName: null,
        nickName: "默认名称",
        avatar: "",
        name: "",
        phone: "",
        status: 0,
        accountUserInfoResponse: {
          totalIncome: '0.00',
          withdrawnAmount: '0.00',
          withdrawingAmount: '0.00',
          availableForWithdrawalAmount: '0.00'
        }
      },
      sagePosterImg: '',
      businessPosterImg: '',
      showOfficialModel: false
    }
  },
  created() {
    if (sessionStorage.getItem('new_user')) {
      this.showOfficialModel = true;
    }

    axios.get(this.$store.state.host + 'tt/wechat/common/config/images'
    ).then((result) => {
      let data = result.data.body;
      data.forEach(item => {
        if (item.name === "withdrawal-customer") {
          this.imgSrc = this.$store.state.imgHost + item.value;
        }
      })
    })

    this.userInfo = JSON.parse(sessionStorage.getItem('user'));
    this.setUserInfo(this.userInfo.id)
  },
  methods: {
    showSage() {
      this.$router.push({'name': 'sagePoster'})
    },
    showBusiness() {
      this.$router.push({'name': 'businessPosterView'})
    },
    hideOfficialModel() {
      const fillScreen = document.querySelector("#official-qr-model-box");

      // 点击打开按钮 改变display属性值
      sessionStorage.removeItem('new_user');
      fillScreen.style.display = "none";
    },
    showQr() {
      const open = document.querySelector("#showQr");

      const close = document.querySelector("#qr-close");
      // 获取整个大的模态框
      const fillScreen = document.querySelector("#qr-model-box");

      // 点击打开按钮 改变display属性值
      fillScreen.style.display = "flex";

      // // 做打开功能
      open.addEventListener("click", () => {
        // 点击打开按钮 改变display属性值
        fillScreen.style.display = "flex";
      });

      // 关闭功能
      close.addEventListener("click", () => {
        fillScreen.style.display = "none";
      });
    },
    setUserInfo(userId) {
      axios.get(this.$store.state.host + 'tt/wechat/user/info', {
        params: {
          userId: userId
        }
      }).then((userInfo) => {
        this.userInfo = userInfo.data.body;
        if (this.userInfo === null || this.userInfo.status !== 1) {
          this.$router.push({name : 'expertRegistration'});
          return;
        }

        sessionStorage.setItem('user', JSON.stringify(this.userInfo))
      }).catch(function (msg) {
        alert('获取用户数据失败！')
        console.log(msg)
        this.$router.push({name : 'businessSettleFlow'})
      })
    }
  },
  mounted() {
    document.title = '达人中心'; // 设置页面标题
  },
}
</script>