<template>
  <div class="apply-withdrawal">
    <div class="center">
    <div class="apply-box">
      <div class="title">
        提现金额
      </div>
      <div class="bank-content" v-if="this.bankNumber !== null && this.bankNumber !== ''">
        <span style="color: #aaaaaa;">
        到账银行卡：
        </span>
        <span>
          {{showBank}}
        </span>
      </div>
      <div class="input">
        <span class="logo">¥</span>
        <input type="number" pattern="/^(\d+)?(\.\d{1,2})?$/"
               onkeyup= "if(!/^(\d+)?(\.\d{1,2})?$/.test(this.value)){this.value = parseFloat(this.value).toFixed(2)}"
               title="请输入正确的价格格式" placeholder="请输入提现金额" v-model="money" name="money" id="money">
        <span class="all" @click="allBalance">全部提现</span>
      </div>
      <div class="balance">
        <span class="balance-title">可提现金额</span>
        <span class="money">¥&nbsp;<span v-text="balance"></span></span>
        <a class="record-link" href="/withdrawal/record">提现记录</a>
      </div>
      <div class="commit">
        <span>提现服务费率:</span>
        <span>6%</span>
      </div>
    </div>
    </div>
    <div class="center">
      <div class="msg">
        <div class="qr">
          <img :src="imgSrc">
          <img v-if="imgSrc === null" src="../assets/loading.gif">
        </div>
        <div class="text">
          <span>请长按识别二维码添加财务可服务微信办理提现，添加成功后请提供注册手机号，并发送指令:"我要提现"，即可提现！</span>
        </div>
      </div>
      <span style="margin-top: 5px;width: 90%;text-align: left;">说明：提现一般在3个工作日内提现到账，正常工作时间为工作日早9点30-晚18:30；节假日除外。</span>
      <button class="submitBtn" @click="apply">申请提现</button>
    </div>

    <div v-if="showOfficialModel" class="model-box" id="official-qr-model-box">
      <div class="model-content">
        <div class="title">
          <span class="modal-title" style="font-size: 20px;font-weight: bold">温馨提示</span>
        </div>
        <img :src="imgSrc">
        <div style="display: grid;padding: 5px 0 20px 0;margin-top: 20px">
          <span>提现到账还需识别二维码，添加财务客服企业微信方可办理完成，只需把注册手机号发给财务即可。请注意，节假日期间不办理提现。</span>
        </div>
        <button class="close-btn" @click="clickPersonCenter" id="official-qr-close">关闭</button>
      </div>
    </div>

  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      balance: '0.00',
      money: '',
      userInfo: null,
      imgSrc: null,
      bankName: null,
      bankNumber: null,
      showBank: null,
      showOfficialModel: false,
    }
  },
  methods: {
    getBankInfo() {
      axios.get(this.$store.state.host + 'tt/wechat/account/bank/info', {
        params: {
          userId: this.userInfo.id
        }
      }).then(response => {
        let bankInfo = response.data.body
        this.bankNumber = bankInfo.bankNumber;
        this.bankName = bankInfo.bankName;
        this.showBank = this.bankName + '(' + this.bankNumber.slice(-4) + ')';
      })
    },
    allBalance() {
      this.money = this.balance
    },
    setUserInfo(userId) {
      axios.get(this.$store.state.host + 'tt/wechat/user/info', {
        params: {
          userId: userId
        }
      }).then((userInfo) => {
        this.userInfo = userInfo.data.body;
        if (this.userInfo === null || this.userInfo.status !== 1) {
          this.$router.push({name : 'expertRegistration'});
          return;
        }

        sessionStorage.setItem('user', JSON.stringify(this.userInfo))
      }).catch(function (msg) {
        alert('获取用户数据失败！')
        console.log(msg)
        this.$router.push({name : 'businessSettleFlow'})
      })
    },
    clickPersonCenter() {
      this.$router.push({name: 'personalCenter'})
    },
    apply() {
      if (this.bankNumber === null || this.bankNumber === '') {
        alert('您还未绑定银行卡，请前往绑定银行账户')
        this.$router.push({'name': 'personalInfo'});
        return;
      }

      this.money = document.getElementById('money').value;
      if (this.money === '' || this.money < 0.01) {
        alert('请输入正确的金额')
        return;
      }

      if (this.money < 0.1) {
        alert('最低提现金额为0.1')
        return;
      }

      if (parseFloat(this.money) > parseFloat(this.balance)) {
        alert('余额不足！')
        return;
      }

      let params = {
        "userId": this.userInfo.id,
        "withdrawalAmount": this.money
      }
      axios.post(this.$store.state.host + 'tt/wechat/account/withdrawal/apply', params
      ).then((msg) => {
        this.showOfficialModel = true
      }).catch(function (msg) {
        console.log(msg)
        alert(msg.data.msg)
      })
    }
  },
  mounted() {
    document.title = '申请提现'; // 设置页面标题
  },
  created() {
    this.userInfo = JSON.parse(sessionStorage.getItem('user'));
    if (this.userInfo === null) {
      this.$router.push({name : 'expertRegistration'})
    }
    this.setUserInfo(this.userInfo.id);
    this.balance = this.userInfo.accountUserInfoResponse.availableForWithdrawalAmount ? this.userInfo.accountUserInfoResponse.availableForWithdrawalAmount : '0.00'

    this.getBankInfo();
    axios.get(this.$store.state.host + 'tt/wechat/common/config/images'
    ).then((result) => {
      let data = result.data.body;
      data.forEach(item => {
        if (item.name === "withdrawal") {
          this.imgSrc = this.$store.state.imgHost + item.value;
          console.log(this.imgSrc)
        }
      })
    })
  }
}
</script>

<style>

/* 模态框 start */
.apply-withdrawal .model-box {
  display: ruby;
  flex-wrap: wrap;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.45);
}
.apply-withdrawal .model-box .model-content {
  width: 90%;
  margin-top: 100px;
  border-radius: 5px;
  padding: 0 20px;
  /* 盒子阴影 */
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.2);
  background-color: #fff;
  padding-bottom: 10px;
}
.apply-withdrawal .model-box .model-content .title {
  /* 弹性布局 */
  display: grid;
  /* 让子元素水平与两端对齐 */
  justify-content: center;
  min-height: 60px;
  padding-top: 20px;
  /* 鼠标移入呈现移动光标 */
  cursor: move;
}
.apply-withdrawal .model-box .close {
  font-size: 40px;
}
.apply-withdrawal .model-box .close-btn {
  border: 0;
  padding: 10px;
  width: 80%;
  margin-top: 10px;
  border-radius: 20px;
  color: white;
  font-size: 18px;
  background-color: #598bfd;
  margin-bottom: 20px;
}

.apply-withdrawal .model-box .model-content .title span {
  font-size: 18px;
  font-weight: bold;
  color: #333;
}
.apply-withdrawal .model-box .model-content form .form-input input:hover {
  border-color: #c0c4cc;
}
/* 输入框获取焦点变色 */
.apply-withdrawal .model-box .model-content form .form-input input:focus {
  border-color: #409eff;
}
.apply-withdrawal .model-box .model-content form .form-input button {
  /* 让按钮浮动到右侧 */
  float: right;
  margin-top: 10px;
}

.apply-withdrawal .model-box img {
  width: 40%;
}

.apply-withdrawal .commit {
  margin-top: 5px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  color: #A1A1A1;
}
.bank-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-top: 20px;
  padding-bottom: 18px;
  border-top: 1px solid #E1E1E1;
  border-bottom: 1px solid #E1E1E1;
  font-size: 16px;
}

.apply-withdrawal .submitBtn {
  width: 90%;
  color: white;
  padding: 10px;
  font-size: 16px;
  border-radius: 5px;
  border: none;
  background-color: rgb(73, 101, 242);
  margin-top: 40px;
}
.apply-withdrawal .msg {
  align-items: center;
  width: 90%;
  border-radius: 10px;
  padding: 30px 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin-top: 20px;
  background-color: rgb(231, 232, 236);
}
.apply-withdrawal .msg .text {
  color: rgb(125, 128, 135);
  width: 70%;
  text-align: left;
}

.apply-withdrawal .qr img {
  width: 50px;
}
.apply-withdrawal .apply-box {
  padding-bottom: 20px;
  width: 90%;
  margin-top: 20px;
  border-radius: 10px;
  background-color: white;
}

.apply-withdrawal .balance {
  text-align: left;
  margin-top: 20px;
  font-size: 18px;
  width: 100%;
}

.apply-withdrawal .balance .money {
  color: #498ff2;
  padding-left: 10px;
}

.apply-withdrawal .balance .record-link {
  text-decoration: none;
  color: #498ff2;
  float: right;
}
.apply-withdrawal .input {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 20px;
  padding-bottom: 5px;
  border-bottom: 1px solid #888888;
  width: 100%;
}

.apply-withdrawal .input .logo {
  font-size: 30px;
}

.apply-withdrawal .input .all {
  padding-top: 5px;
  color: #498ff2;
  font-size: 20px;
  text-align: left;
}

.apply-withdrawal .input input {
  margin-left: 5px;
  width: 65%;
  font-size: 22px;
  border: none;
  outline: none;
  height: 30px;
  font-weight: 200;
}

.apply-withdrawal .title {
  margin-top: 20px;
  font-size: 28px;
  text-align: left;
}
</style>