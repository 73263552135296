<template>
  <div class="finance-submit">

    <el-row class="content-box">
      <el-col :span="24"><div class="page-title">申请提现</div></el-col>
      <el-col :span="24" style="text-align: left"><span>主体类型</span></el-col>
      <div style="display: flex;flex-wrap: wrap;justify-content: space-between;width: 100%;">
        <div class="center"><div id="sub-body-1" :class="type === 1 ? 'sub-body-click' : ''" class="sub-body" @click="clickSubBody(1)">个人</div></div>
        <div class="center"><div id="sub-body-2" :class="type === 2 ? 'sub-body-click' : ''" class="sub-body" @click="clickSubBody(2)">企业</div></div>
        <div class="center"><div id="sub-body-3" :class="type === 3 ? 'sub-body-click' : ''" class="sub-body" @click="clickSubBody(3)">个体工商户</div></div>
      </div>
    </el-row>

    <el-row class="content-box" v-if="type === 1">
      <el-col :span="24"><div class="title">达人信息</div></el-col>
      <el-col :span="24"><div class="input-title">*<span>身份证正面</span></div> </el-col>
      <div class="identity-card"><img src="../../assets/finance/identity-card-front.png"></div>
      <el-col :span="24"><div class="input-title">*<span>身份证背面</span></div> </el-col>
      <div class="identity-card"><img src="../../assets/finance/identity-card-back.png"></div>
      <el-col :span="24">
        <div class="identity-card-prompt">
          <span>1、证件照片须为彩色扫描件或彩色图片</span>
          <span>2、图片仅支持BMP、JPG、JPEG、PNG格式，大小不超过2MB</span>
        </div>
      </el-col>
      <el-col :span="24">
        <div class="identity-card-input-prompt">
          <span>以下信息须与证件照片信息一致，可通过上传证件后系统自动获取</span>
        </div>
      </el-col>
      <el-row class="identity-card-input-box border-bottom">
        <el-col :span="6">
          <div class="input-title">*<span>证件姓名</span></div>
        </el-col>
        <el-col :span="18" class="item-center">
          <input class="identity-card-input" placeholder="请输入证件姓名"/>
        </el-col>
      </el-row>
      <el-row class="identity-card-input-box border-bottom">
        <el-col :span="6">
          <div class="input-title">*<span>证件号码</span></div>
        </el-col>
        <el-col :span="18" class="item-center">
          <input class="identity-card-input" placeholder="请输入证件号码"/>
        </el-col>
      </el-row>
      <el-row class="identity-card-input-box">
        <el-col :span="8">
          <div class="input-title">*<span>证件起止日期</span></div>
        </el-col>
        <el-col :span="8" class="center">
          <div :class="expertType === 1 ? 'click-identity-card-type' : ''" class="identity-card-type" @click="clickIdCardType('expert', 1)">非长久有效</div>
        </el-col>
        <el-col :span="8" class="center">
          <div :class="expertType === 2 ? 'click-identity-card-type' : ''" class="identity-card-type" @click="clickIdCardType('expert', 2)">长久有效</div>
        </el-col>
      </el-row>

      <el-row class="datetime-pick row-margin" v-if="expertType === 1">
        <el-col :span="3">
          <img src="../../assets/finance/date.png" width="20">
        </el-col>
        <el-col :span="10">
          <el-date-picker
              v-model="id_start_time"
              type="datetime"
              placeholder="请选择开始日期"
              format="YYYY/MM/DD hh:mm:ss"
              value-format="x"
              class="input"
              unlink-panels
          />
        </el-col>
        <el-col :span="1">~</el-col>
        <el-col :span="10">
          <el-date-picker
              v-model="id_end_time"
              type="datetime"
              placeholder="请选择结束日期"
              format="YYYY/MM/DD hh:mm:ss"
              value-format="x"
              class="input"
              unlink-panels
          />
        </el-col>
      </el-row>

      <el-row class="datetime-pick row-margin" v-if="expertType === 2">
        <el-col :span="3">
          <img src="../../assets/finance/date.png" width="20">
        </el-col>
        <el-col :span="20">
          <el-date-picker
              v-model="id_start_time"
              type="datetime"
              placeholder="请选择开始日期"
              format="YYYY/MM/DD hh:mm:ss"
              value-format="x"
              class="input"
              unlink-panels
          />
        </el-col>
      </el-row>
    </el-row>

    <el-row class="content-box" v-if="type === 2 || type === 3">
      <el-col :span="24"><div class="title">达人信息</div></el-col>
      <el-col :span="24"><div class="input-title">*
        <span v-if="type === 3">个体工商户营业执照</span>
        <span v-if="type === 2">营业执照</span>
      </div> </el-col>
      <div class="identity-card"><img src="../../assets/finance/certificate.png"></div>
      <el-col :span="24">
        <div class="identity-card-prompt">
          <span>1、证件照片须为彩色扫描件或彩色图片</span>
          <span>2、图片仅支持BMP、JPG、JPEG、PNG格式，大小不超过2MB</span>
        </div>
      </el-col>
      <el-col :span="24">
        <div class="identity-card-input-prompt">
          <span>以下信息须与证件照片信息一致，可通过上传证件后系统自动获取</span>
        </div>
      </el-col>
      <el-row class="identity-card-input-box border-bottom">
        <el-col :span="6">
          <div class="input-title">*<span>公司名称</span></div>
        </el-col>
        <el-col :span="18" class="item-center">
          <input class="identity-card-input" placeholder="请输入公司名称"/>
        </el-col>
      </el-row>
      <el-row class="identity-card-input-box border-bottom">
        <el-col :span="6">
          <div class="input-title">*<span>证件号码</span></div>
        </el-col>
        <el-col :span="18" class="item-center">
          <input class="identity-card-input" placeholder="请输入营业执照编码"/>
        </el-col>
      </el-row>
      <el-row class="identity-card-input-box">
        <el-col :span="8">
          <div class="input-title">*<span>证件起止日期</span></div>
        </el-col>
        <el-col :span="8" class="center">
          <div :class="certificateType === 1 ? 'click-identity-card-type' : ''" class="identity-card-type" @click="clickIdCardType('certificate', 1)">非长久有效</div>
        </el-col>
        <el-col :span="8" class="center">
          <div :class="certificateType === 2 ? 'click-identity-card-type' : ''" class="identity-card-type" @click="clickIdCardType('certificate', 2)">长久有效</div>
        </el-col>
      </el-row>

      <el-row class="datetime-pick row-margin" v-if="certificateType === 1">
        <el-col :span="3">
          <img src="../../assets/finance/date.png" width="20">
        </el-col>
        <el-col :span="10">
          <el-date-picker
              v-model="id_start_time"
              type="datetime"
              placeholder="请选择开始日期"
              format="YYYY/MM/DD hh:mm:ss"
              value-format="x"
              class="input"
              unlink-panels
          />
        </el-col>
        <el-col :span="1">~</el-col>
        <el-col :span="10">
          <el-date-picker
              v-model="id_end_time"
              type="datetime"
              placeholder="请选择结束日期"
              format="YYYY/MM/DD hh:mm:ss"
              value-format="x"
              class="input"
              unlink-panels
          />
        </el-col>
      </el-row>
      <el-row class="datetime-pick row-margin" v-if="certificateType === 2">
        <el-col :span="3">
          <img src="../../assets/finance/date.png" width="20">
        </el-col>
        <el-col :span="21">
          <el-date-picker
              v-model="id_start_time"
              type="datetime"
              placeholder="请选择开始日期"
              format="YYYY/MM/DD hh:mm:ss"
              value-format="x"
              class="input"
              unlink-panels
          />
        </el-col>
      </el-row>
    </el-row>

    <el-row class="content-box" style="margin-top: -8px" v-if="type === 2 || type === 3">
      <el-row class="identity-card-input-box border-bottom">
        <el-col :span="6">
          <div class="input-title">*<span>商户简介</span></div>
        </el-col>
        <el-col :span="18" class="item-center">
          <input class="identity-card-input" placeholder="请输入公司名称"/>
        </el-col>
      </el-row>
      <el-col :span="24" style="text-align: left;">
        <span class="notify">支付成功页及账单上实现的商户简称</span>
      </el-col>

      <el-row class="identity-card-input-box border-bottom">
        <el-col :span="6">
          <div class="input-title">*<span>官网链接</span></div>
        </el-col>
        <el-col :span="18" class="item-center">
          <input class="identity-card-input" v-model="website_link"/>
        </el-col>
      </el-row>
      <el-col :span="24" style="text-align: left;">
        <span class="notify">与商户有关的网站地址即可：示例：http://www.xxx.com</span>
      </el-col>

      <el-row class="identity-card-input-box">
        <el-col :span="6">
          <div class="input-title">*<span>所属行业</span></div>
        </el-col>
        <el-col :span="18" class="item-center">
          todo
        </el-col>
      </el-row>

      <el-row class="identity-card-input-box">
        <el-col :span="24">
          <div class="input-title">*<span>经营地址</span></div>
        </el-col>
        <el-col :span="24" class="item-center">
          <el-tree-select
              v-model="businessCtiy"
              :data="ctiyData"
              filterable
              :render-after-expand="false"
          />
        </el-col>
      </el-row>
      <el-col :span="24" style="text-align: left;">
        <input class="identity-card-input" placeholder="请输入详细地址"/>
      </el-col>

      <el-row class="identity-card-input-box border-bottom">
        <el-col :span="6">
          <div class="input-title">*<span>区县编码</span></div>
        </el-col>
        <el-col :span="18" class="item-center">
          <input class="identity-card-input" placeholder="请输入区县编码"/>
        </el-col>
      </el-row>
    </el-row>

    <el-row class="content-box" v-if="type === 2 || type === 3">
      <el-col :span="24"><div class="title">法人信息</div></el-col>
      <el-col :span="24"><div class="input-title">*<span>身份证正面</span></div> </el-col>
      <div class="identity-card"><img src="../../assets/finance/identity-card-front.png"></div>
      <el-col :span="24"><div class="input-title">*<span>身份证背面</span></div> </el-col>
      <div class="identity-card"><img src="../../assets/finance/identity-card-back.png"></div>
      <el-col :span="24">
        <div class="identity-card-prompt">
          <span>1、证件照片须为彩色扫描件或彩色图片</span>
          <span>2、图片仅支持BMP、JPG、JPEG、PNG格式，大小不超过2MB</span>
        </div>
      </el-col>
      <el-col :span="24">
        <div class="identity-card-input-prompt">
          <span>以下信息须与证件照片信息一致，可通过上传证件后系统自动获取</span>
        </div>
      </el-col>
      <el-row class="identity-card-input-box border-bottom">
        <el-col :span="6">
          <div class="input-title">*<span>证件姓名</span></div>
        </el-col>
        <el-col :span="18" class="item-center">
          <input class="identity-card-input" placeholder="请输入证件姓名"/>
        </el-col>
      </el-row>
      <el-row class="identity-card-input-box border-bottom">
        <el-col :span="6">
          <div class="input-title">*<span>证件号码</span></div>
        </el-col>
        <el-col :span="18" class="item-center">
          <input class="identity-card-input" placeholder="请输入证件号码"/>
        </el-col>
      </el-row>
      <el-row class="identity-card-input-box">
        <el-col :span="8">
          <div class="input-title">*<span>证件起止日期</span></div>
        </el-col>
        <el-col :span="8" class="center">
          <div :class="legalPersonType === 1 ? 'click-identity-card-type' : ''" class="identity-card-type" @click="clickIdCardType('legal_person', 1)">非长久有效</div>
        </el-col>
        <el-col :span="8" class="center">
          <div :class="legalPersonType === 2 ? 'click-identity-card-type' : ''" class="identity-card-type" @click="clickIdCardType('legal_person', 2)">长久有效</div>
        </el-col>
      </el-row>

      <el-row class="datetime-pick row-margin" v-if="legalPersonType === 1">
        <el-col :span="3">
          <img src="../../assets/finance/date.png" width="20">
        </el-col>
        <el-col :span="10">
          <el-date-picker
              v-model="id_start_time"
              type="datetime"
              placeholder="请选择开始日期"
              format="YYYY/MM/DD hh:mm:ss"
              value-format="x"
              class="input"
              unlink-panels
          />
        </el-col>
        <el-col :span="1">~</el-col>
        <el-col :span="10">
          <el-date-picker
              v-model="id_end_time"
              type="datetime"
              placeholder="请选择结束日期"
              format="YYYY/MM/DD hh:mm:ss"
              value-format="x"
              class="input"
              unlink-panels
          />
        </el-col>
      </el-row>

      <el-row class="datetime-pick row-margin" v-if="legalPersonType === 2">
        <el-col :span="3">
          <img src="../../assets/finance/date.png" width="20">
        </el-col>
        <el-col :span="21">
          <el-date-picker
              v-model="id_start_time"
              type="datetime"
              placeholder="请选择开始日期"
              format="YYYY/MM/DD hh:mm:ss"
              value-format="x"
              class="input"
              unlink-panels
          />
        </el-col>
      </el-row>
        <el-col :span="24" class="identity-card-address margin-10">
          <div class="input-title">*<span>证件登记地址</span></div>
        </el-col>
        <el-col :span="24" class="item-center">
          <div class="identity-card-address">
            <input class="identity-card-input border-bottom" placeholder="请输入证件登记地址"/>
          </div>
        </el-col>
        <el-row class="identity-card-input-box border-bottom">
          <el-col :span="6">
            <div class="input-title">*<span>法人邮箱</span></div>
          </el-col>
          <el-col :span="18" class="item-center">
            <input class="identity-card-input" placeholder="请填写邮箱地址"/>
          </el-col>
        </el-row>
        <el-col :span="24">
          <div class="identity-card-prompt">
            <span style="font-size: 16px;">用于接收开户邮件及日常业务通知</span>
          </div>
        </el-col>
    </el-row>

    <el-row class="content-box" v-if="type === 2 || type === 3">
      <el-col :span="24"><div class="title">管理员信息</div></el-col>
      <el-col :span="24"><div class="input-title">*<span>身份证正面</span></div> </el-col>
      <div class="identity-card"><img src="../../assets/finance/identity-card-front.png"></div>
      <el-col :span="24"><div class="input-title">*<span>身份证背面</span></div> </el-col>
      <div class="identity-card"><img src="../../assets/finance/identity-card-back.png"></div>
      <el-col :span="24">
        <div class="identity-card-prompt">
          <span>1、证件照片须为彩色扫描件或彩色图片</span>
          <span>2、图片仅支持BMP、JPG、JPEG、PNG格式，大小不超过2MB</span>
        </div>
      </el-col>
      <el-col :span="24">
        <div class="identity-card-input-prompt">
          <span>以下信息须与证件照片信息一致，可通过上传证件后系统自动获取</span>
        </div>
      </el-col>
      <el-row class="identity-card-input-box border-bottom">
        <el-col :span="6">
          <div class="input-title">*<span>证件姓名</span></div>
        </el-col>
        <el-col :span="18" class="item-center">
          <input class="identity-card-input" placeholder="请输入证件姓名"/>
        </el-col>
      </el-row>
      <el-row class="identity-card-input-box border-bottom">
        <el-col :span="6">
          <div class="input-title">*<span>证件号码</span></div>
        </el-col>
        <el-col :span="18" class="item-center">
          <input class="identity-card-input" placeholder="请输入证件号码"/>
        </el-col>
      </el-row>
      <el-row class="identity-card-input-box">
        <el-col :span="8">
          <div class="input-title">*<span>证件起止日期</span></div>
        </el-col>
        <el-col :span="8" class="center">
          <div class="identity-card-type click-identity-card-type">非长久有效</div>
        </el-col>
        <el-col :span="8" class="center">
          <div class="identity-card-type">长久有效</div>
        </el-col>
      </el-row>

      <el-row class="datetime-pick row-margin">
        <el-col :span="3">
          <img src="../../assets/finance/date.png" width="20">
        </el-col>
        <el-col :span="10">
          <el-date-picker
              v-model="id_start_time"
              type="datetime"
              placeholder="请选择开始日期"
              format="YYYY/MM/DD hh:mm:ss"
              value-format="x"
              class="input"
              unlink-panels
          />
        </el-col>
        <el-col :span="1">~</el-col>
        <el-col :span="10">
          <el-date-picker
              v-model="id_end_time"
              type="datetime"
              placeholder="请选择结束日期"
              format="YYYY/MM/DD hh:mm:ss"
              value-format="x"
              class="input"
              unlink-panels
          />
        </el-col>
      </el-row>
    </el-row>

    <el-row class="content-box" style="padding-bottom: 20px;">
      <el-col :span="24"><div class="title">结算信息</div></el-col>
      <el-row class="identity-card-input-box">
        <el-col :span="8">
          <div class="input-title">*<span>证件起止日期</span></div>
        </el-col>
        <el-col :span="8" class="center">
          <div class="identity-card-type click-identity-card-type">对私</div>
        </el-col>
      </el-row>

      <el-row class="identity-card-input-box border-bottom">
        <el-col :span="6">
          <div class="input-title">*<span>银行卡号</span></div>
        </el-col>
        <el-col :span="18" class="item-center">
          <input class="identity-card-input" placeholder="请输入借记卡银行卡号"/>
        </el-col>
      </el-row>

      <el-col :span="24" class="identity-card-address margin-10">
        <div class="input-title">*<span>所属省市</span></div>
      </el-col>
      <el-col :span="24" class="item-center tree-select">
        <el-tree-select
            v-model="settlementCtiy"
            :data="ctiyData"
            filterable
            :render-after-expand="false"
        />
      </el-col>

      <el-col :span="24" class="identity-card-address margin-10">
        <div class="input-title">*<span>开户银行</span></div>
      </el-col>
      <el-col :span="24" class="item-center">
        <div class="identity-card-address">
          <input class="identity-card-input border-bottom" placeholder="请输入开户行"/>
        </div>
      </el-col>

      <el-row class="identity-card-input-box border-bottom">
        <el-col :span="6">
          <div class="input-title">*<span>开户支行</span></div>
        </el-col>
        <el-col :span="18" class="item-center">
          <input class="identity-card-input" placeholder="请输入开户支行信息"/>
        </el-col>
      </el-row>

      <el-row class="identity-card-input-box border-bottom">
        <el-col :span="6">
          <div class="input-title">*<span>联行号</span></div>
        </el-col>
        <el-col :span="18" class="item-center">
          <input class="identity-card-input" placeholder="请输入联行号"/>
        </el-col>
      </el-row>

      <el-col :span="24" class="center">
        <button class="next-btn" @click="submit">提交审核</button>
      </el-col>
    </el-row>
  </div>
</template>

<style>
.finance-submit .tree-select .el-select__wrapper {
  background-color: #f1f1f1;
}

.finance-submit .next-btn {
  width: 100%;
  padding: 10px;
  margin-top: 20px;
  color: white;
  background-color: #3579f6;
  border: 1px solid #3579f6;
  border-radius: 5px;
}

.finance-submit .sub-body-click {
  background-color: #007BFF;
  color: white;
}
.finance-submit .notify {
  color: #A1A1A1;
}

.finance-submit .identity-card-address {
  width: 100%;
}

.finance-submit .margin-10 {
  margin-top: 10px;
  margin-bottom: 10px;
}

.finance-submit .datetime-pick {
  background-color: #F2F2F2;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 5px;
  align-items: center;
}

.finance-submit .datetime-pick .input {
  width: 100%;
}

.finance-submit .datetime-pick .el-input__prefix {
  display: none;
}

.finance-submit .datetime-pick .el-input__wrapper {
  border: none;
  background-color: #F2F2F2;
  box-shadow: none;
}

.identity-card-type {
  font-size: 14px;
  width: 70%;
  border-radius: 5px;
  border: 2px solid #92C6FF;
  padding: 5px;
  color: #007BFF;
  font-weight: 400;
}

.finance-submit .click-identity-card-type {
  background-color: #007BFF;
  color: white;
  border: 2px solid #007BFF;
}

.finance-submit .identity-card-input-box {
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 16px;
}

.finance-submit .border-bottom {
  border-bottom: 1px solid #F4F4F4;
}

.finance-submit .item-center {
  display: flex!important;
  align-items: center!important;
}

.finance-submit .center {
  display: flex!important;
  flex-wrap: wrap!important;
  justify-content: center!important;
}

.identity-card-input {
  border: none;
  outline: none;
  width: 100%;
}

.identity-card-input::placeholder {
  color: #A1A1A1;
}

.identity-card-input-prompt {
  background-color: #EFF5FB;
  color: #A1A1A1;
  text-align: left;
  padding: 5px 10px 5px 10px;
  margin-top: 15px;
}

.identity-card-prompt {
  display: grid;
  font-size: 12px;
  color: #A1A1A1;
  text-align: left;
}

.identity-card {
  padding: 10px 0 10px 0;
}

.identity-card img {
  width: 60%;
}

.title {
  text-align: left;
  font-size: 20px;
  font-weight: bold;
}

.input-title {
  color: red;
  padding: 5px 0 5px 0;
  text-align: left;
}

.input-title span {
  color: black;
  padding-left: 5px;
}

.finance-submit {
  width: 100vw; /* 使div的宽度为视口宽度 */
  padding-bottom: 50px;
  background-color: #f0f0f0;
  font-size: 16px;
}

.finance-submit .page-title {
  padding-bottom: 20px;
  padding-top: 10px;
  font-size: 20px;
  font-weight: 400;
}

.finance-submit .content-box {
  background-color: white;
  padding: 10px 20px 10px 20px;
  margin-bottom: 10px;
}

.sub-body {
  color: #007BFF;
  border: 2px solid #007BFF;
  padding: 5px 10px;
  border-radius: 5px;
  min-width: 100px;
}
</style>

<script>
export default {
  data() {
    return {
      isVisible: false,
      id_start_time: null,
      id_end_time: null,
      website_link: 'https://www.baidu.com',
      type: 1,
      expertType: 1,
      certificateType: 1,
      legalPersonType: 1,
      settlementCtiy: null,
      ctiyData: [
        {
          value: '1',
          label: 'Level one 1',
          children: [
            {
              value: '1-1',
              label: 'Level two 1-1',
              children: [
                {
                  value: '1-1-1',
                  label: 'Level three 1-1-1',
                },
              ],
            },
          ],
        }
      ]
    }
  },
  methods: {
    toggleVisibility() {
      this.isVisible = !this.isVisible; // 切换元素的显示状态
    },
    clickSubBody(value) {
      this.type = value;
    },
    clickIdCardType(type, value) {
      if (type === 'expert') {
        this.expertType = value;
      }

      if (type === 'certificate') {
        this.certificateType = value;
      }

      if (type === 'legal_person') {
        this.legalPersonType = value;
      }
    },
  }
}
</script>