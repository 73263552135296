import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import expertRegistrationView from '../views/HomeView.vue'
import personalCenter from '../views/PersonalCenterView.vue'
import invitedList from '../views/InvitedExpertListView.vue'
import detailedList from '../views/DetailedListView.vue'
import applyWithdrawal from '../views/ApplyWithdrawalView.vue'
import withdrawalRecord from '../views/WithdrawalRecordView.vue'
import settleFlow from '../views/business-settle/SettleFlowView.vue'
import registrationView from '../views/business-settle/RegistrationView.vue'
import paymentView from "@/views/business-settle/PaymentView.vue";
import customerView from "@/views/business-settle/CustomerQRView.vue"
import agreement from "@/views/AboutView.vue"
import sagePoster from "@/views/SagePosterView.vue"
import orderRecordView from "@/views/OrderRecordView.vue"
import merchantRecordsView from "@/views/MerchantRecordsView.vue"
import learnCenterView from "@/views/LearnCenterView.vue"
import sagePosterView from "@/views/SagePosterView.vue";
import businessPosterView from "@/views/BusinessPosterView.vue";
import merchantStoreInfo from "@/views/business-settle/merchant-info/StoreInformationView.vue";
import qualificationInfoView from "@/views/business-settle/merchant-info/QualificationInfoView.vue";
import merchantInfoView from "@/views/business-settle/merchant-info/MerchantInfoView.vue";
import SystemUpgradeView from "@/views/SystemUpgradeView.vue";
import personalInfo from "@/views/personal/PersonalInfo.vue";
import financeSubmitView from "@/views/finance-info/FinanceSubmitView.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'businessSettleFlow',
    component: settleFlow
  },
  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/personal/center',
    name: 'personalCenter',
    component: personalCenter
  },
  {
    path: '/invited/list',
    name: 'InvitedExpertList',
    component: invitedList
  },
  {
    path: '/detailed/list',
    name: 'detailedList',
    component: detailedList
  },
  {
    path: '/apply/withdrawal',
    name: 'applyWithdrawal',
    component: applyWithdrawal
  },
  {
    path: '/withdrawal/record',
    name: 'withdrawalRecord',
    component: withdrawalRecord
  },
  {
    path: '/expert/registration',
    name: 'expertRegistration',
    component: expertRegistrationView
  },
  {
    path: '/business/registration',
    name: 'businessRegistration',
    component: registrationView
  },
  {
    path: '/business/payment',
    name: 'businessPayment',
    component: paymentView
  },
  {
    path: '/business/customer',
    name: 'customerView',
    component: customerView
  },
  {
    path: '/agreement',
    name: 'agreement',
    component: agreement
  },
  {
    path: '/sage/poster',
    name: 'sagePoster',
    component: sagePoster
  },
  {
    path: '/order/records',
    name: 'orderRecords',
    component: orderRecordView
  },
  {
    path: '/merchant/records',
    name: 'merchantRecords',
    component: merchantRecordsView
  },
  {
    path: '/learn/center',
    name: 'learnCenter',
    component: learnCenterView
  },
  {
    path: '/sage/poster',
    name: 'sagePoster',
    component: sagePosterView
  },
  {
    path: '/business/poster',
    name: 'businessPosterView',
    component: businessPosterView
  },
  {
    path: '/merchant/store/info',
    name: 'merchantStoreInfo',
    component: merchantStoreInfo
  },
  {
    path: '/merchant/qualification/info',
    name: 'merchantQualificationInfo',
    component: qualificationInfoView,
  },
  {
    path: '/merchant/info',
    name: 'merchantInfo',
    component: merchantInfoView,
  },
  {
    path: '/system/upgrade',
    name: 'SystemUpgradeView',
    component: SystemUpgradeView
  },
  {
    path: '/personal/information',
    name: 'personalInfo',
    component: personalInfo
  },
  {
    path: '/finance/submit',
    name: 'financeSubmit',
    component: financeSubmitView
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {

  if (to.path === '/expert/registration' &&
      sessionStorage.getItem('user') != null
  ) {
    next('/personal/center');
  }

  if (sessionStorage.getItem('user') != null ||
      to.path === '/expert/registration' ||
      to.path === '/'
  ) {
    next();
    return;
  }

  next('/expert/registration');
})

export default router
