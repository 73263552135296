<template>
  <div class="withdrawals-record">
    <div v-for="record in items" :key="record.id" class="detail-box">
      <div class="box">
        <div class="img">
          <img src="../assets/money-logo.png">
        </div>
        <div class="text">
          <span class="name">提现</span><br>
          <span class="msg">{{record.withdrawalDate}}</span><br>
          <span class="msg" v-if="record.commission !== '0.00' && record.withdrawalStatus === 1">提现手续费: {{record.commission}}</span>
        </div>
        <div class="money">
          <span>¥{{record.withdrawalAmount}}</span><br>
          <span v-if="record.withdrawalStatus === 0 || record.withdrawalStatus === 2" style="color: #aaaaaa;font-weight: bold;">提现中</span>
          <span v-if="record.withdrawalStatus === 1" style="color: #aaaaaa;font-weight: bold;">提现成功</span>
          <span v-if="record.withdrawalStatus === -1" style="color: red;font-weight: bold;">提现失败</span>
        </div>
        <div class="prompt" v-if="record.withdrawalStatus === -1">
          <span>失败原因:</span>
          <span>{{record.failReason}}</span>
        </div>
      </div>
    </div>
    <div v-if="!hasData" style="margin-top: 10px;">暂无数据</div>

    <div ref="observerElement"></div>
  </div>
</template>


<style>

body {
  margin: 0;
}
.withdrawals-record .prompt {
  background-color: #EFF5FB;
  color: #A1A1A1;
  margin-top: 5px;
  text-align: left;
  padding: 10px;
  width: 100%;
}

.withdrawals-record {
  min-height: 100%;
  background-color: white;
  padding: 10px 25px 10px 25px;
}

.withdrawals-record .detail-box .money {
  font-size: 16px;
  padding: 10px;
  width: 25%;
}
.withdrawals-record .detail-box .box {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  border-bottom: 1px #D1D1D1 solid;
  padding-top: 10px;
  padding-bottom: 10px;
  align-items: center;
}

.withdrawals-record .detail-box img {
  width: 45px;
}

.withdrawals-record .detail-box .name {
  font-size: 16px;
  font-weight: bold;
}

.withdrawals-record .detail-box .text {
  width: 50%;
  padding-left: 5px;
  padding-top: 5px;
  text-align: left;
}

.withdrawals-record .detail-box .msg {
  font-size: 14px;
  color: #aaaaaa;
}

</style>

<script>
import axios from "axios";
import { useStore } from 'vuex';
import {onBeforeUnmount, onMounted, ref} from "vue";

export default {
  setup() {
    let hasData = ref(false);
    const items = ref([]); // 列表数据
    const observerElement = ref(null); // 用于触发加载的元素

    const userInfo = JSON.parse(sessionStorage.getItem('user'));
    if (userInfo === null) {
      return {
        items,
        hasData,
        observerElement
      };
    }

    const observer = ref(null); // IntersectionObserver 实例
    let page = 1; // 初始化页码
    let pageSize = 20;
    let page_total = 1;
    const store = useStore();

    // 加载更多内容的函数
    const loadMoreItems = () => {
      if (page > page_total) {
        return {
          items,
          hasData,
          observerElement,
        };
      }
      axios.get(store.state.host + 'tt/wechat/account/withdrawal/page', {
        params: {
          pageIndex: page,
          pageSize: pageSize,
          condition: userInfo.id
        }
      }).then((withdrawalRecords) => {
        if (withdrawalRecords.data.body.data.length > 0) {
          items.value = [...items.value, ...withdrawalRecords.data.body.data];
          hasData.value = true;
          page++; // 页码递增
        }
        page_total = withdrawalRecords.data.body.page.page_total;
      })
    };

    // 创建 IntersectionObserver 实例
    onMounted(() => {
      observer.value = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          loadMoreItems();
        }
      }, {
        root: null, // 视口作为根元素
        rootMargin: '0px',
        threshold: 1.0 // 完全可见时触发
      });

      if (observerElement.value) {
        observer.value.observe(observerElement.value);
      }
    });

    // 停止观察并断开 IntersectionObserver 实例
    onBeforeUnmount(() => {
      if (observer.value) {
        observer.value.disconnect();
      }
    });

    return {
      items,
      hasData,
      observerElement
    };
  },
  created() {
    this.userInfo = JSON.parse(sessionStorage.getItem('user'));
  },
  data() {
    return {
      userInfo: null
    }
  },
  mounted() {
    document.title = '提现记录'; // 设置页面标题
  }
}
</script>